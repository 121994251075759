import React from "react";
import H1 from "../../components/h1";
import SEO from "../../components/seo";
import Par from "../../components/Par";

// Download link - Download GESA Information: Flexible Sigmoidoscopy - http://www.fbrennangastro.com.au/downloads/gesaflexiblesigmoidoscopy.pdf

const title = "Flexible Sigmoidoscopy";
const Services = () => {
	return (
		<div>
			<SEO
				title={title}
				keywords={[
					title,
					`Gastroenterology services`,
					`Endoscopy services`,
					`Gastroenterologist`,
					`Hepatologist`,
					`Dr Frank`,
					`Dr Brennan`,
					`Dr Spiro`,
					`Dr Raftopoulos`,
					`Dr Ian Yusoff`,
					`Dr Dev Segarajasingam`,
					`Dr Sam Rao`,
					`Dr Duy Pham`,
					`Dr Claire Gordon`,
				]}
				description={title}
			/>
			<H1 text={title} />
			<Par>
				Flexible sigmoidoscopy is a procedure used to see inside the sigmoid colon and rectum. The procedure can detect
				inflamed tissue, abnormal growths and ulcers.
			</Par>
			<Par>
				Flexible sigmoidoscopy is a procedure that allows your doctor to examine the rectum and the lower (sigmoid)
				colon. A thin flexible tube about 60cm long is used. It is inserted gently into the anus and advanced slowly
				into the rectum and the lower colon.
			</Par>
			<Par>
				It is similar to, but not the same as, a colonoscopy. A sigmoidoscopy only examines up to the sigmoid colon (the
				last third of the colon) while colonoscopy examines the whole large bowel.
			</Par>
			<Par>
				This 20-minute procedure helps identify early signs of cancer and can help diagnose unexplained changes in bowel
				habits, abdominal pain, bleeding from the anus and weight loss.
			</Par>
		</div>
	);
};

export default Services;
